import React from 'react'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'

//local imports
import {KTSVG} from '../../../../_metronic/helpers'
import Chip from './Chip'
import {makeDate} from '../../../../util'
import {NA} from '../../../../config/constant'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  bgcolor: '#1e1e2d',
  border: '2px solid #000',
  boxShadow: 24,
}

type exploreModalProps = {
  open: boolean
  handleClose(): void
  data: any
}

const ExploreModal = ({open, handleClose, data}: exploreModalProps) => {
  // console.log("🚀 ~ file: ExploreModal.tsx:28 ~ ExploreModal ~ data:", data)
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={style} className='px-8 pb-12 pt-0'>
        <div className='text-end mb-2'>
          {/* begin::Modal title */}
          {/* <h2 className='fw-bolder text-white'>Media</h2> */}
          {/* end::Modal title */}

          {/* begin::Close */}
          <div
            onClick={handleClose}
            className='btn btn-icon btn-sm btn-active-icon-primary'
            style={{cursor: 'pointer'}}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
          </div>
          {/* end::Close */}
        </div>
        <div className='mx-4 runded-0 bg-white'>
          <div className='row g-0'>
            <div className='col-md-7 bg-secondary rounded-5'>
              <img
                style={{objectFit: 'contain'}}
                height={'500px'}
                src={data?.url}
                className='card-img-top rounded-3'
                alt='...'
              />
            </div>
            <div className='col-md-5 py-3 ps-5'>
              <div className='row mt-1'>
                <div className='col'>
                  <div className='d-flex flex-column'>
                    <div className='fw-bold text-muted mb-2'>Created At</div>
                    {data?.dateTime && <p className='fs-6 fw-bold'>{makeDate(data?.dateTime)}</p>}
                  </div>
                </div>
                <div className='col'>
                  <div className='d-flex flex-column'>
                    <div className='fw-bold text-muted mb-2'>Address</div>
                    <p className='fs-6 fw-bold'>
                      {data?.client?.street
                        ? data?.client?.street +
                          ' ' +
                          data?.client?.city +
                          ' ' +
                          data?.client?.state
                        : NA}
                    </p>
                  </div>
                </div>
              </div>
              <div style={{height: '70px'}} className='row mt-4'>
                <div className='col'>
                  <div className='d-flex flex-column'>
                    <div className='fw-bold text-muted mb-2'>Latitude</div>
                    <p className='fs-6 fw-bold'>{data?.latitude ? data?.latitude : NA}</p>
                  </div>
                </div>
                <div className='col'>
                  <div className='d-flex flex-column'>
                    <div className='fw-bold text-muted mb-2'>Longitude</div>
                    <p className='fs-6 fw-bold'>{data?.longitude ? data?.longitude : NA}</p>
                  </div>
                </div>
              </div>
              <div className='fw-bold text-muted mb-2 mt-4'>Tags</div>
              <div className='mt-auto h-100px overflow-auto'>
                {data?.tags?.length > 0 &&
                  data?.tags?.map(({service}: any, idx: number) => (
                    <>
                      <h5 key={idx} className='card-title badge badge-secondary fs-5 me-2 mb-2'>
                        {service?.name}
                      </h5>
                    </>
                  ))}
              </div>
              <div className='col-12'>
                <div className='d-flex flex-column'>
                  <div className='fw-bold text-muted mb-2'>
                    Caption <span className='text-white'>({data?.id})</span>{' '}
                  </div>
                  <p className='fs-6 fw-bold'>{data?.caption}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  )
}

export default ExploreModal
